var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "business-client-card", style: { height: "85px" } },
    [
      _c(
        "div",
        {
          staticClass: "fw500 flex bet a-center",
          on: { click: _vm.data.click },
        },
        [
          _c(
            "div",
            { staticClass: "client-mobile-header" },
            [
              _vm.client.name
                ? _c("user-avatar", {
                    style: {
                      "margin-top": "-5px",
                      "margin-right": "20px",
                    },
                    attrs: {
                      user: _vm.client.user || _vm.client,
                      "img-class": "table-client__photo",
                    },
                  })
                : _vm._e(),
              _c("div", {
                style: { "margin-bottom": "10px", "margin-right": "15px" },
                domProps: { textContent: _vm._s(_vm.client.name) },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "business-client-card__main",
          style: {
            display: "flex",
            "justify-content": "space-between",
            width: "100%",
            "border-bottom": "none",
          },
        },
        [
          _c("div", { staticClass: "item-card__main_name" }, [
            _c("div", { style: { display: "flex" } }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Phone")) },
              }),
              _c("span", {
                style: { "margin-right": "7px" },
                domProps: { textContent: _vm._s(_vm.client.phone) },
              }),
            ]),
            _c(
              "div",
              { style: { display: "flex" } },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("email")) },
                }),
                _vm.client.email
                  ? _c("mail-lnk", {
                      style: { "margin-right": "7px" },
                      attrs: { data: _vm.client.email },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item-card__main_name" }, [
            _c(
              "div",
              {
                style: {
                  display: "grid",
                  "justify-content": "end",
                  "margin-top": "-35px",
                },
              },
              [
                _vm.client.phone
                  ? _c("phone-btn", {
                      style: {
                        "margin-bottom": "10px",
                      },
                      attrs: { data: _vm.client.phone },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.client.click.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.client.phone
                  ? _c("whatsapp-btn", { attrs: { data: _vm.client.phone } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }